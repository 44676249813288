<template>
  <el-table :data="collegeAliases">
    <el-table-column label="名稱" prop="name" />
    <el-table-column label="名稱語言">
      <template slot-scope="scope">
        {{ $t(`language.${scope.row.lang}`) }}
      </template>
    </el-table-column>
    <el-table-column label="Actions">
      <template slot-scope="scope">
        <span
          class="action-icon"
          @click="$emit('setCollegeAliasToEdit', scope.row)"
        >
          <i class="fas fa-edit" />
        </span>
        <span
          v-if="!scope.row.originalName"
          class="warning-icon"
          @click="$emit('deleteCollegeAlias', scope.row)"
        >
          <i class="fas fa-trash-alt" />
        </span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    collegeAliases: {
      type: Array,
      default: () => []
    },
    lang: {
      type: String,
      default: ""
    }
  },
  computed: {
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    }
  }
};
</script>
