var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid container"},[_c('Breadcrumb'),_c('h1',[_vm._v(_vm._s(_vm.pageTitle))]),_vm._v(" 因為有些大學可能不會被叫全名，像是哈佛、MIT 等等，這裡可以幫各個大學設定另外的名稱，方便之後更容易搜索。 "),_c('hr',{staticClass:"separate-line"}),_c('el-form',{attrs:{"label-width":"100px"},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.checkAliasIsExist.apply(null, arguments)}}},[_c('el-form-item',{attrs:{"label":"大學名稱"}},[_c('CollegeSelector',{attrs:{"value":_vm.collegeAlias.schoolId},on:{"change":school => {
            _vm.$store.commit('collegeAlias/setCollegeAlias', {
              schoolId: school.id
            });
            _vm.$store.commit('collegeAlias/setCollege', school);
            _vm.fetchCollegeAliases(school.id);
          }}})],1),(_vm.collegeAlias.schoolId)?[_c('el-form-item',{attrs:{"label":"名稱語言"}},[_c('LanguageSelector',{attrs:{"value":_vm.collegeAlias.lang},on:{"change":lang =>
              _vm.$store.commit('collegeAlias/setCollegeAlias', {
                lang
              })}})],1),_c('el-form-item',{attrs:{"label":"新名稱"}},[_c('el-input',{attrs:{"value":_vm.collegeAlias.name},on:{"input":name =>
              _vm.$store.commit('collegeAlias/setCollegeAlias', {
                name
              })}})],1),_c('el-form-item',[_c('el-checkbox',{attrs:{"value":_vm.collegeAlias.isDefault,"true-label":1,"false-label":0},on:{"change":isDefault =>
              _vm.$store.commit('collegeAlias/setCollegeAlias', {
                isDefault
              })}},[_vm._v(" 預設顯示名字 ")])],1),_c('el-button',{attrs:{"type":"primary","native-type":"submit"}},[_vm._v(" Save ")])]:_vm._e()],2),(_vm.collegeAlias.schoolId)?[_c('hr',{staticClass:"separate-line"}),_c('h2',[(_vm.lang === 'EN-US')?_c('span',[_vm._v(" "+_vm._s(`${_vm.getEnglishSchoolName(_vm.college)} 現有名稱`)+" ")]):_c('span',[_vm._v(" "+_vm._s(`${_vm.getChineseSchoolName(_vm.college)} 現有名稱`)+" ")])]),_c('CollegeAliasesTable',{attrs:{"lang":_vm.lang,"collegeAliases":_vm.collegeAliases},on:{"deleteCollegeAlias":_vm.deleteCollegeAlias,"setCollegeAliasToEdit":_vm.setCollegeAliasToEdit}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }