
<template>
  <el-select
    style="width: 100%"
    :value="value"
    @change="onChange"
    placeholder="請輸入要新增名稱的大學名稱搜尋大學"
    clearable
    filterable
    remote
    :remote-method="fetchColleges"
  >
    <el-option
      v-for="college in colleges"
      :key="college.id"
      :value="college.id"
      :label="
        `${college.name[langList[lang]]} ${
          lang === 'EN-US' ? '' : `(${college.name['en']})`
        } - ${college.city}, ${college.state}.`
      "
    >
    </el-option>
  </el-select>
</template>

<script>
import schoolApi from "@/apis/school.js";

export default {
  props: {
    value: {
      type: Number,
      default: null
    }
  },
  computed: {
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    }
  },
  data() {
    return {
      colleges: []
    };
  },
  methods: {
    async fetchColleges(keyword) {
      const { schools } = await schoolApi.getSchoolByKeyword(keyword);
      this.colleges = schools;
    },
    onChange(schoolId) {
      const school = this.colleges.find(({ id }) => id === schoolId);
      this.$emit("change", school);
    }
  }
};
</script>
