<template>
  <div class="container-fluid container">
    <Breadcrumb />
    <h1>{{ pageTitle }}</h1>
    因為有些大學可能不會被叫全名，像是哈佛、MIT 等等，這裡可以幫各個大學設定另外的名稱，方便之後更容易搜索。
    <hr class="separate-line" />
    <el-form label-width="100px" @submit.prevent.native="checkAliasIsExist">
      <el-form-item label="大學名稱">
        <CollegeSelector
          :value="collegeAlias.schoolId"
          @change="
            school => {
              $store.commit('collegeAlias/setCollegeAlias', {
                schoolId: school.id
              });
              $store.commit('collegeAlias/setCollege', school);
              fetchCollegeAliases(school.id);
            }
          "
        />
      </el-form-item>
      <template v-if="collegeAlias.schoolId">
        <el-form-item label="名稱語言">
          <LanguageSelector
            :value="collegeAlias.lang"
            @change="
              lang =>
                $store.commit('collegeAlias/setCollegeAlias', {
                  lang
                })
            "
          />
        </el-form-item>
        <el-form-item label="新名稱">
          <el-input
            :value="collegeAlias.name"
            @input="
              name =>
                $store.commit('collegeAlias/setCollegeAlias', {
                  name
                })
            "
          />
        </el-form-item>
        <el-form-item>
          <el-checkbox
            :value="collegeAlias.isDefault"
            :true-label="1"
            :false-label="0"
            @change="
              isDefault =>
                $store.commit('collegeAlias/setCollegeAlias', {
                  isDefault
                })
            ">
            預設顯示名字
          </el-checkbox>
        </el-form-item>
        <el-button type="primary" native-type="submit">
          Save
        </el-button>
      </template>
    </el-form>
    <template v-if="collegeAlias.schoolId">
      <hr class="separate-line" />
      <h2>
        <span v-if="lang === 'EN-US'">
          {{ `${getEnglishSchoolName(college)} 現有名稱` }}
        </span>
        <span v-else>
          {{ `${getChineseSchoolName(college)} 現有名稱` }}
        </span>
      </h2>
      <CollegeAliasesTable
        :lang="lang"
        :collegeAliases="collegeAliases"
        @deleteCollegeAlias="deleteCollegeAlias"
        @setCollegeAliasToEdit="setCollegeAliasToEdit"
      />
    </template>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { LanguageSelector } from "@/components/selector";
import CollegeSelector from "@/views/collegeAliases/CollegeSelector";
import CollegeAliasesTable from "@/components/collegeAliases/CollegeAliasesTable";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },
  components: {
    Breadcrumb,
    LanguageSelector,
    CollegeSelector,
    CollegeAliasesTable
  },
  computed: {
    pageTitle() {
      return this.$t("pageTitle.collegeAliases");
    },
    college() {
      return this.$store.getters["collegeAlias/college"];
    },
    collegeAlias() {
      return this.$store.getters["collegeAlias/collegeAlias"];
    },
    collegeAliases() {
      return this.$store.getters["collegeAlias/collegeAliases"];
    },
    lang() {
      return this.$store.getters["user/langValue"];
    },
    langList() {
      return {
        "ZH-TW": "tw",
        "EN-US": "en",
        "ZH-CN": "cn"
      };
    }
  },
  data() {
    return {
      isEditMode: false
    };
  },
  methods: {
    fetchCollegeAliases(schoolId) {
      this.$store.dispatch("collegeAlias/getCollegeAliases", { schoolId });
    },
    getEnglishSchoolName(college) {
      return `${college.name[this.langList[this.lang]]} - ${college.city}, ${college.state}.`;
    },
    getChineseSchoolName(college) {
      return `${college.name[this.langList[this.lang]]} (${college.name["en"]}) - ${college.city}, ${college.state}.`;
    },
    async checkAliasIsExist() {
      if (!this.isEditMode) {
        let isExistAlias = this.collegeAliases.some(
          ({ name, lang }) => name === this.collegeAlias.name && lang === this.collegeAlias.lang
        );
        if (isExistAlias) {
          this.$message.error("該名稱已存在現有學校名稱中了！");
          return;
        }

        const { alias_or_name_is_exist, schools } = await this.$store.dispatch(
          "collegeAlias/checkAliasIsExist",
          this.collegeAlias
        );
  
        const existSchools = () => (
          Object.keys(schools).map(schoolKey => (
            `<li>
              ${this.lang === "EN-US"
              ? this.getEnglishSchoolName(schools[schoolKey])
              : this.getChineseSchoolName(schools[schoolKey])
            }
            </li>`
          )).join("")
        );
        if (alias_or_name_is_exist) {
          try {
            await this.$confirm(
              `這所學校的名稱已被其他學校使用過：<br /><ul>${existSchools()}</ul>是否繼續儲存？`,
              this.$t("message.notice"),
              {
                confirmButtonText: "儲存",
                cancelButtonText: "取消",
                dangerouslyUseHTMLString: true,
                type: "warning"
              }
            );
          } catch (e) {
            return false;
          }
        }
      }
      this.submitCollegeAlias();
    },
    submitCollegeAlias() {
      if (this.isEditMode) {
        this.updateCollegeAlias();
        this.isEditMode = false;
      } else {
        this.createCollegeAlias();
      }
    },
    async createCollegeAlias() {
      try {
        await this.$store.dispatch("collegeAlias/createCollegeAlias");
        this.fetchCollegeAliases(this.collegeAlias.schoolId);
        this.$store.commit("collegeAlias/setCollegeAlias", {
          name: ""
        });
        this.$message.success(this.$t("message.create_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async updateCollegeAlias() {
      try {
        await this.$store.dispatch("collegeAlias/updateCollegeAlias");
        this.fetchCollegeAliases(this.collegeAlias.schoolId);
        this.$store.commit("collegeAlias/setCollegeAlias", {
          name: ""
        });
        this.$message.success(this.$t("message.update_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async deleteCollegeAlias(collegeAlias) {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }
      try {
        await this.$store.dispatch("collegeAlias/deleteCollegeAlias", collegeAlias);
        this.$store.dispatch("collegeAlias/getCollegeAliases", {
          schoolId: this.collegeAlias.schoolId
        });
        this.$message.success(this.$t("message.delete_success"));
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async setCollegeAliasToEdit(collegeAlias) {
      this.$store.commit("collegeAlias/setCollegeAlias", collegeAlias);
      this.isEditMode = true;
    }
  }
};
</script>
<style scoped>
.container {
  margin-bottom: 40px;
}

::v-deep .el-checkbox {
  margin-top: -12px;
  display: block;
}
</style>